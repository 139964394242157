/** @format */

import React from 'react';
// Import Swiper React components
import { Carousel } from 'react-responsive-carousel';
import '../carousel.css';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

export function VerticalCarousel({ children }) {
	return (
		<>
			<Carousel
				axis='vertical'
				showStatus={false}
				showArrows={false}
				showThumbs={false}
				autoPlay
				interval={3000}
				infiniteLoop
				swipeable={false}
				dynamicHeight={false}>
				{children}
			</Carousel>
		</>
	);
}

export function SlideCarousel({ children }) {
	const arrowStyles = {
		position: 'absolute',
		zIndex: 2,
		top: 'calc(50% - 15px)',
		width: 30,
		height: 30,
		cursor: 'pointer',
	};
	return (
		<>
			<Carousel
				showStatus={false}
				showArrows={true}
				showThumbs={false}
				showIndicators={true}
				dynamicHeight={false}
				stopOnHover={false}
				swipeable={false}
				renderArrowPrev={(onClickHandler, hasPrev, label) =>
					hasPrev && (
						<FaChevronLeft
							onClick={onClickHandler}
							title={label}
							style={{ ...arrowStyles, left: 5 }}
						/>
					)
				}
				renderArrowNext={(onClickHandler, hasNext, label) =>
					hasNext && (
						<FaChevronRight
							onClick={onClickHandler}
							title={label}
							style={{ ...arrowStyles, right: 5 }}
						/>
					)
				}>
				{children}
			</Carousel>
		</>
	);
}

export function TestimonialCarousel({ isMobile, children }) {
	return (
		<>
			<Carousel
				showStatus={false}
				showArrows={false}
				showThumbs={false}
				showIndicators={false}
				dynamicHeight={false}
				stopOnHover={false}
				autoPlay
				interval={2000}
				infiniteLoop
				centerMode
				centerSlidePercentage={isMobile ? '100' : '33.3'}>
				{children}
			</Carousel>
		</>
	);
}
