/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MaxContainer } from "./Container.styled";
import { Link } from "react-scroll";
import { Para } from "./Typography.styled";
import { BsFacebook, BsTwitter, BsInstagram } from "react-icons/bs";
import Popup from "./Popup.component";
import parse from "html-react-parser";

// Styled COmponents START
const FooterContainer = styled.div`
  top: 0;
  padding: 20px 20px;
  background: linear-gradient(
    90deg,
    rgba(239, 79, 74, 1) 0%,
    rgba(94, 45, 135, 1) 51%
  );
`;

const Menu = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 10px;
  }
  ul {
    display: flex;
    gap: 40px;
    li {
      position: relative;
      text-transform: uppercase;
      text-decoration: none;
      list-style: none;
      font-size: var(--para);
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      cursor: pointer;
    }
    @media screen and (max-width: 500px) {
      margin-top: 30px;
      flex-direction: column;
      gap: 10px;
      align-self: flex-start;
    }
    & .active::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(239, 79, 74, 1) 0%,
        rgba(94, 45, 135, 1) 100%
      );
    }
    & ::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(239, 79, 74, 1) 0%,
        rgba(94, 45, 135, 1) 100%
      );
      transition: all 0.3s ease-in-out;
    }
    & :hover::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(239, 79, 74, 1) 0%,
        rgba(94, 45, 135, 1) 100%
      );
      transition: all 0.3s ease-in-out;
    }
  }
  .buttonDiv {
    display: flex;
    gap: 40px;
    img {
      cursor: pointer;
    }
    @media screen and (max-width: 500px) {
      margin-top: 30px;
    }
  }
`;

const CreditDiv = styled.div`
  padding: 10px 0 0;
  display: grid;
  align-items: center;
  justify-content: center;

  .flex {
    display: flex;
    gap: 5px;
  }
  a:hover {
    color: blue;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

// Styled COmponents END`

function Footer() {
  const [termsAndCondition, setTermsAndCondition] = useState('');
  const [isTnCPopupOpen, setIsTnCPopupOpen] = useState(false);
  useEffect(() => {
    const termsCondition = async () => {
      let response = await fetch(
        '/api/terms_and_policy.json'
        // , {
        // 	method: 'GET',
        // 	headers: {
        // 		'Content-Type': 'application/json',
        // 		Accept: 'application/json',
        // 	},
        // 	body: `{"type":"terms_conditions"}`,
        // }
      );
      let data = await response.json();
      setTermsAndCondition(data.data.terms_and_policy);
      console.log('terms_conditions', data.data.terms_and_policy);
    };
    termsCondition();
  }, []);

  return (
    <>
      <FooterContainer>
        <MaxContainer>
          <img
            src="./images/logoWhite.png"
            alt=""
            width="130px"
            height="auto"
          />
          <Menu>
            <ul>
              <li>
                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="photogranth"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Photogranth
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  News
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Contact Us
                </Link>
              </li>
              <li onClick={() => setIsTnCPopupOpen(true)}>Privacy Policy</li>
            </ul>
            <div className="buttonDiv">
              <BsFacebook color="#fff" size="40px" />
              <BsTwitter color="#fff" size="40px" />
              <BsInstagram color="#fff" size="40px" />
            </div>
          </Menu>
        </MaxContainer>
      </FooterContainer>
      <CreditDiv>
        <div className="flex">
          <Para sizeSmall="13px">
            @ Design, Developed & Managed by{" "}
            <a
              href="https://www.Photogranth.com"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Photogranth.com
            </a>{" "}
          </Para>
          <a
            href="https://www.Photogranth.com"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img src="/images/logo.png" alt="" height="30px" />
          </a>
        </div>
      </CreditDiv>
      {isTnCPopupOpen && (
        <Popup setIsTnCPopupOpen={setIsTnCPopupOpen}>
          {termsAndCondition ? parse(termsAndCondition) : ""}
        </Popup>
      )}
    </>
  );
}

// About        Photogranth        Pricing        Blog        News        Contact Us

export default Footer;
