/** @format */

import React from 'react';
import styled from 'styled-components';
import { GrFormClose } from 'react-icons/gr';

const PopupBg = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PopupContainer = styled.div`
	position: relative;
	padding: 45px 30px 30px;
	background-color: #fff;
	border-radius: 5px;
	width: ${(props) => (props.width ? props.width : '70vw')};
	height: ${(props) => (props.height ? props.height : '80vh')};
	@media screen and (max-width: 500px) {
		width: 100%;
		height: 100%;
		padding: 45px 20px 20px;
	}
`;

const PopupContent = styled.div`
	position: relative;
	z-index: 100;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none; /* Firefox */
	}
`;

const CloseBtn = styled(GrFormClose)`
	font-size: 40px;
	position: absolute;
	right: 2px;
	top: 2px;
	cursor: pointer;
`;

function Popup({ width, height, setIsTnCPopupOpen, children }) {
	return (
		<PopupBg>
			<PopupContainer width={width} height={height}>
				<CloseBtn onClick={() => setIsTnCPopupOpen(false)} />
				<PopupContent>{children}</PopupContent>
			</PopupContainer>
		</PopupBg>
	);
}

export default Popup;
