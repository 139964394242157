/** @format */

import styled from 'styled-components';

export const PrimaryBtn = styled.button`
	background: linear-gradient(
		90deg,
		rgba(239, 79, 74, 1) 0%,
		rgba(94, 45, 135, 1) 100%
	);
	border: none;
	border-radius: 0;
	color: #fff;
	transition: all 0.4s ease;
	cursor: pointer;

	&:hover {
		transition: all 0.4s ease;
		background: linear-gradient(
			90deg,
			rgba(94, 45, 135, 1) 0%,
			rgba(239, 79, 74, 1) 100%
		);
	}
`;
