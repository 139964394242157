/** @format */

import styled from 'styled-components';

export const Heading = styled.h2`
	font-size: ${(props) => (props.size ? props.size : 'var(--heading)')};
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	text-align: ${(props) => (props.align ? props.align : '')};
	line-height: ${(props) => (props.lineheight ? props.lineheight : '')};
	margin: ${(props) => (props.margin ? props.margin : '')};
	text-transform: uppercase;
	@media screen and (max-width: 500px) {
		margin: ${(props) => (props.marginSmall ? props.marginSmall : '')};
		font-size: ${(props) =>
			props.sizeSmall ? props.sizeSmall : 'var(--heading-small)'};
		text-align: ${(props) => (props.alignSmall ? props.alignSmall : '')};
		line-height: ${(props) =>
			props.lineheightSmall ? props.lineheightSmall : ''};
	}
`;

export const GredientHeading = styled.h2`
	font-size: ${(props) => (props.size ? props.size : 'var(--heading)')};
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	display: inline-block;
	text-transform: uppercase;
	text-align: ${(props) => (props.align ? props.align : '')};
	background: ${(props) =>
		props.opposite
			? 'linear-gradient(90deg,rgba(94, 45, 135, 1)0%, rgba(239, 79, 74, 1)  100%)'
			: 'linear-gradient(90deg,rgba(239, 79, 74, 1) 0%,rgba(94, 45, 135, 1) 100%)'};
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	@media screen and (max-width: 500px) {
		font-size: ${(props) =>
			props.sizeSmall ? props.sizeSmall : 'var(--heading-small)'};
		text-align: ${(props) => (props.alignSmall ? props.alignSmall : 'left')};
	}
`;

export const Para = styled.p`
	font-size: ${(props) => (props.size ? props.size : 'var(--para)')};
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	text-align: ${(props) => (props.align ? props.align : '')};
	margin: ${(props) => (props.margin ? props.margin : '')};
	padding: ${(props) => (props.padding ? props.padding : '')};
	align-self: ${(props) => (props.alignSelf ? props.alignSelf : '')};
	text-transform: ${(props) => (props.uppercase ? 'uppercase' : '')};
	@media screen and (max-width: 500px) {
		font-size: ${(props) =>
			props.sizeSmall ? props.sizeSmall : 'var(--para-small)'};
		align-self: ${(props) =>
			props.alignSelfSmall ? props.alignSelfSmall : ''};
		text-align: ${(props) => (props.alignSmall ? props.alignSmall : 'left')};
		margin: ${(props) => (props.marginSmall ? props.marginSmall : '')};
		padding: ${(props) => (props.paddingSmall ? props.paddingSmall : '')};
	}
`;
