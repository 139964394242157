/** @format */

import styled from 'styled-components';

export const MaxContainer = styled.div`
	max-width: 2000px;
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	padding: ${(props) => (props.padding ? props.padding : '')};
	background: ${(props) => (props.bg ? props.bg : '')};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
	}
	@media screen and (max-width: 500px) {
		padding: ${(props) => (props.paddingSmall ? props.paddingSmall : '20px')};
		max-width: 100vw;
	}
`;
