/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/Footer.component";
import Header from "../components/Header.component";
import {
  GredientHeading,
  Heading,
  Para,
} from "../components/Typography.styled";
import { MaxContainer } from "../components/Container.styled";
import { PrimaryBtn } from "../components/Button.styled";
import {
  SlideCarousel,
  VerticalCarousel,
} from "../components/Carousel.component";
import { useFormik } from "formik";

// Form Validation

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.phone) {
    errors.phone = "Required";
  } else if (values.phone.length < 10) {
    errors.phone = "Enter a valid phome number";
  } else if (!/^[0-9]{10}$/i.test(values.phone)) {
    errors.phone = "Enter a valid phome number";
  }
  if (!values.detail) {
    errors.detail = "Required";
  }

  return errors;
};

//

// Styled Components START

const Main = styled.main`
  & :nth-child(even) {
    &::after {
      background: linear-gradient(
        270deg,
        rgba(239, 79, 74, 1) 0%,
        rgba(94, 45, 135, 1) 30%,
        rgba(255, 255, 255, 1) 60%
      );
    }
  }
  & :nth-child(odd) {
    &::after {
      background: linear-gradient(
        90deg,
        rgba(239, 79, 74, 1) 0%,
        rgba(94, 45, 135, 1) 30%,
        rgba(255, 255, 255, 1) 60%
      );
    }
  }
`;
const Aligner = styled.div`
  display: flex;
  justify-content: ${(props) => (props.align ? props.align : "")};
  @media screen and (max-width: 500px) {
    justify-content: ${(props) =>
    props.alignSmall ? props.alignSmall : "start"};
  }
`;
const Div = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.coloums ? props.coloums : "")};
  background: ${(props) => (props.bg ? props.bg : "")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: ${(props) => (props.padding ? props.padding : "50px")};
  height: ${(props) => (props.height ? props.height : "")};
  width: ${(props) => (props.width ? props.width : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  align-self: ${(props) => (props.alignself ? props.alignself : "")};
  gap: ${(props) => (props.gap ? props.gap : "")};
  @media screen and (max-width: 500px) {
    grid-template-columns: ${(props) =>
    props.coloumsSmall ? props.coloumsSmall : ""};
    padding: ${(props) => (props.paddingSmall ? props.paddingSmall : "0px")};
    width: ${(props) => (props.widthSmall ? props.widthSmall : "")};
    max-width: ${(props) => (props.maxWidthSmall ? props.maxWidthSmall : "")};
    margin: ${(props) => (props.marginSmall ? props.marginSmall : "")};
    align-self: ${(props) =>
    props.alignselfSmall ? props.alignselfSmall : ""};
  }
`;
const DualDiv = styled.div`
  display: grid;
  height: ${(props) => (props.height ? props.height : "")};
  background: ${(props) => (props.bg ? props.bg : "")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-template-columns: ${(props) =>
    props.coloums ? props.coloums : "1fr 1fr"};
  padding: ${(props) => (props.padding ? props.padding : "50px")};
  width: ${(props) => (props.width ? props.width : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100vw")};
  margin: ${(props) => (props.margin ? props.margin : "0 auto")};
  gap: ${(props) => (props.gap ? props.gap : "")};
  & img {
    max-width: 80%;
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: ${(props) =>
    props.coloumsSmall ? props.coloumsSmall : "1fr"};
    padding: ${(props) => (props.paddingSmall ? props.paddingSmall : "0px")};
    width: ${(props) => (props.widthSmall ? props.widthSmall : "100%")};
    max-width: ${(props) =>
    props.maxWidthSmall ? props.maxWidthSmall : "100vw"};
    margin: ${(props) => (props.marginSmall ? props.marginSmall : "0 auto")};
    height: ${(props) => (props.heightSmall ? props.heightSmall : "")};
    & img {
      max-width: 100%;
      text-align: center;
    }
  }
`;

const SwitchButton = styled.button`
  width: 100%;
  padding: 15px 0;
  font-size: var(--para);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  cursor: pointer;
  background: ${(props) => (props.active ? "#e22f5d" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#e22f5d")};
  border: 1px solid #e22f5d;
  @media screen and (max-width: 500px) {
    font-size: var(--para-small);
    padding: 10px;
  }
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: 45% 10% 45%;
  height: 700px;
  margin: 90px auto 0;
  .absolute {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    .absolute {
      position: relative;
    }
  }
`;

const StackedNumber = styled.p`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 40px;
  color: #fff;
  background-color: ${(props) => (props.bg ? props.bg : "")};
  @media screen and (max-width: 500px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  right: 7%;
  bottom: -50px;
  width: 55%;
  @media screen and (max-width: 500px) {
    position: relative;
    right: -70%;
    bottom: -100px;
    transform: translate(-50%, 0);
    width: 120%;
  }
`;

const SliderBtn = styled.button`
  background: none;
  border: 1px solid #000;
  padding: 12px 50px;
  margin-top: 20px;
  justify-self: start;
  cursor: pointer;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(239, 79, 74, 1) 0%,
      rgba(94, 45, 135, 1) 100%
    );
    color: #fff;
  }
`;

const Input = styled.input`
  padding: 15px 10px;
  font-size: var(--para);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
`;
const TextArea = styled.textarea`
  padding: 15px 10px;
  font-size: var(--para);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
`;

// Styled Components END

function Home() {
  const [Portfolio, setPortfolio] = useState("customer");
  const [Share, setShare] = useState("App");
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    let width = window.innerWidth;
    if (width < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    let reportWindowSize = (e) => {
      let width = e.currentTarget.innerWidth;
      if (width < 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", reportWindowSize);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      detail: "",
    },
    validate,
    onSubmit: (values) => {
      fetch("php/contact.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });
    },
  });

  return (
    <div>
      <Header />
      <Main>
        <MaxContainer paddingSmall="0px" id="about">
          {isMobile && (
            <VerticalCarousel>
              <Div
                bg="url(./images/slide1.png)"
                height="450px"
                heightSmall="500px"
                paddingSmall="30px"
              >
                <Div alignself="center">
                  <Aligner align="left">
                    <GredientHeading size="50px" align="left">
                      Your Very Own App!
                    </GredientHeading>
                  </Aligner>
                  <Heading align="left" size="50px" lineheightSmall="35px">
                    On the Photogranth
                    <br />
                    Platform!
                  </Heading>
                  <SliderBtn>
                    <Para>KNOW MORE</Para>
                  </SliderBtn>
                </Div>
              </Div>
              <Div
                bg="url(./images/slide2BG.png)"
                height="450px"
                heightSmall="500px"
                paddingSmall="30px"
              >
                <Div alignself="center">
                  <Heading align="left" size="50px" lineheightSmall="35px">
                    Deliver photos
                    <br />
                    to your customers
                  </Heading>
                  <Aligner align="left">
                    <GredientHeading size="50px" align="left">
                      the Smart Way!
                    </GredientHeading>
                  </Aligner>
                  <SliderBtn>
                    <Para>KNOW MORE</Para>
                  </SliderBtn>
                </Div>
              </Div>

              <Div
                height="450px"
                heightSmall="500px"
                paddingSmall="30px"
                bg="url(./images/slide3BG.png)"
              >
                <Div alignself="center">
                  <Heading align="left" size="50px" lineheightSmall="35px">
                    3X your reach and
                  </Heading>
                  <Aligner align="left">
                    <GredientHeading size="50px" align="left">
                      grow your business!
                    </GredientHeading>
                  </Aligner>

                  <SliderBtn>
                    <Para>KNOW MORE</Para>
                  </SliderBtn>
                </Div>
              </Div>
            </VerticalCarousel>
          )}
          {!isMobile && (
            <VerticalCarousel>
              <DualDiv
                coloums="60% 1fr"
                height="550px"
                heightSmall="500px"
                paddingSmall="30px"
              >
                <Div alignself="center">
                  <Aligner align="left">
                    <GredientHeading size="45px" align="left">
                      Your Very Own App!
                    </GredientHeading>
                  </Aligner>
                  <Heading align="left" size="45px" lineheight="45px">
                    On the Photogranth
                    <br />
                    Platform!
                  </Heading>
                  <SliderBtn>
                    <Para>KNOW MORE</Para>
                  </SliderBtn>
                </Div>
                <Div bg="url(./images/slide1.png)">
                  <Para size="0px">.</Para>
                </Div>
              </DualDiv>
              <DualDiv
                coloums="50% 1fr"
                bg="url(./images/slide2BG.png)"
                height="550px"
                heightSmall="500px"
                paddingSmall="30px"
              >
                <Div alignself="center">
                  <Heading align="left" size="45px" lineheight="45px">
                    Deliver photos
                    <br />
                    to your customers
                  </Heading>
                  <Aligner align="left">
                    <GredientHeading size="45px" align="left">
                      the Smart Way!
                    </GredientHeading>
                  </Aligner>
                  <SliderBtn>
                    <Para>KNOW MORE</Para>
                  </SliderBtn>
                </Div>
                <Div>
                  <Para size="0px">.</Para>
                </Div>
              </DualDiv>

              <DualDiv
                coloums="50% 1fr"
                height="550px"
                heightSmall="500px"
                paddingSmall="30px"
                bg="url(./images/slide3BG.png)"
              >
                <Div alignself="center">
                  <Heading align="left" size="45px" lineheight="45px">
                    3X your reach and
                  </Heading>
                  <Aligner align="left">
                    <GredientHeading size="45px" align="left">
                      grow your business!
                    </GredientHeading>
                  </Aligner>

                  <SliderBtn>
                    <Para>KNOW MORE</Para>
                  </SliderBtn>
                </Div>
                <Div>
                  <Para size="0px">.</Para>
                </Div>
              </DualDiv>
            </VerticalCarousel>
          )}
        </MaxContainer>
        <MaxContainer bg="#efefef" id="photogranth">
          <DualDiv paddingSmall="40px 0">
            <img src="./images/features.png" alt="" width="100%" />
            <Div alignself="cener" marginSmall="40px 0 0 0">
              <Heading
                align="right"
                alignSmall="left"
                margin="0 0 -20px 0"
                marginSmall="0 0 0px 0"
              >
                Reshape your
                <br />
                Photography Business
              </Heading>
              <Aligner align="end">
                <GredientHeading align="right" alignSmall="left">
                  the Smart Way!
                </GredientHeading>
              </Aligner>
              <Para align="right">
                We are Photogranth, a complete, easy to use platform that brings
                together the entire photo industry on a single page. Our aim is
                to make the business of photography well-connected, accessible
                and easy. We are an end-to-end platform from being a smart photo
                delivery mechanism to a comprehensive photo-industry market
                place. Connect, Promote, Grow with Photogranth.
              </Para>
            </Div>
          </DualDiv>
        </MaxContainer>
        <MaxContainer
          bg="url(./images/photogalleryBG.png)"
          padding="50px 0 0"
          paddingSmall="50px 20px"
        >
          <Div margin="0 auto" maxWidth="1200px">
            <Aligner align="center">
              <GredientHeading opposite align="center" alignSmall="left">
                Customized Photo Gallery for your Customers.{" "}
              </GredientHeading>
            </Aligner>
            <Heading align="center" alignSmall="left">
              For you! Your Own Branded Portfolio.
            </Heading>
            <Div margin="0 auto" padding="0" maxWidth="800px">
              <Para align="center" margin="20px 0 0 0">
                Photogranth offers a double benefit for you as a photographer,
                one that makes you popular amongst your clients and the other
                helps you in being a strong brand.
              </Para>
            </Div>
            <Para align="center" margin="50px 0 0 0">
              <span style={{ color: "#e22f5d" }}>•</span> Create your own
              branded portfolio. <br />
              <span style={{ color: "#e22f5d" }}>•</span> Update it anytime as
              per your requirement. <br />
              <span style={{ color: "#e22f5d" }}>•</span> Create your own
              branded portfolio and share your best work with your customers.
            </Para>
          </Div>
          <Div marginSmall="50px 0 0" margin="0" padding="0">
            {isMobile && (
              <>
                <SlideCarousel>
                  <Div paddingSmall="20px">
                    <div>
                      <Para alignSmall="center">
                        <img
                          src="./images/portfolioMobileNew.png"
                          alt=""
                          width="80%"
                        />
                      </Para>
                      <Para alignSmall="center" uppercase>
                        Create your own <br />
                        branded portfolio that
                        <br />
                        can be shared with
                        <br /> multiple customers.
                      </Para>
                    </div>
                  </Div>
                  <Div paddingSmall="20px">
                    <div>
                      <Para alignSmall="center">
                        <img
                          src="./images/portfolioMobile2New.png"
                          alt=""
                          width="80%"
                        />
                      </Para>
                      <Para alignSmall="center" uppercase>
                        Customers can create their own
                        <br /> customized event wise <br />
                        photo gallery.
                        <br />
                        <br />{" "}
                        <i style={{ color: "#e22f5d" }}>
                          E.g. Trisha weds Sanket
                        </i>
                      </Para>
                    </div>
                  </Div>
                </SlideCarousel>
              </>
            )}
          </Div>
          {!isMobile && (
            <>
              <Aligner align="center">
                <Div
                  padding="0px"
                  width="900px"
                  coloums="1fr 1fr"
                  maxWidth="1200px"
                >
                  <SwitchButton
                    onClick={() => setPortfolio("branded")}
                    active={Portfolio === "branded"}
                  >
                    Your own branded portfolio
                  </SwitchButton>
                  <SwitchButton
                    onClick={() => setPortfolio("customer")}
                    active={Portfolio === "customer"}
                  >
                    A Customized Photo Gallery for Customers
                  </SwitchButton>
                </Div>
              </Aligner>
              {Portfolio === "branded" && (
                <PortfolioGrid>
                  <Para
                    align="center"
                    alignSelf="start"
                    margin="170px 0 0 0"
                    uppercase
                  >
                    Create your own <br />
                    branded portfolio that
                    <br />
                    can be shared with
                    <br /> multiple customers.
                  </Para>
                  <div>
                    <div className="absolute">
                      <img
                        src="./images/photogallery2New.png"
                        alt=""
                        width="670px"
                      />
                    </div>
                  </div>
                  <Para
                    align="center"
                    alignSelf="end"
                    margin="0px 0 170px 0"
                    uppercase
                  >
                    Display your best work.
                    <br /> A portfolio you can
                    <br /> change anytime
                    <br /> with absolute ease.
                  </Para>
                </PortfolioGrid>
              )}
              {Portfolio === "customer" && (
                <PortfolioGrid>
                  <Para
                    align="center"
                    alignSelf="start"
                    margin="170px 0 0 0"
                    uppercase
                  >
                    Customers can create <br />
                    their own
                    <br /> customized event wise <br />
                    photo gallery.
                    <br />
                    <br />{" "}
                    <i style={{ color: "#e22f5d" }}>E.g. Trisha weds Sanket</i>
                  </Para>
                  <div>
                    <div className="absolute">
                      <img
                        src="./images/photogalleryNew.png"
                        alt=""
                        width="650px"
                      />
                    </div>
                  </div>
                  <Para
                    align="center"
                    alignSelf="end"
                    margin="0px 0 170px 0"
                    uppercase
                  >
                    The photo gallery <br />
                    can have folders
                    <br /> as per varied functions.
                    <br />
                    <br />{" "}
                    <i style={{ color: "#e22f5d" }}>
                      E.g. Haldi, Mehendi, Shaadi.
                    </i>
                  </Para>
                </PortfolioGrid>
              )}
            </>
          )}
        </MaxContainer>
        <MaxContainer bg="linear-gradient(90deg,#84d5e7 0%, #debb89 100%)">
          <Div
            width="800px"
            margin="0 auto"
            padding="100px 50px"
            paddingSmall="50px 20px"
            widthSmall="100%"
          >
            <Para align="center" alignSmall="center" size="18px">
              The best gift a Photographer can give his or her customers is the
              chance to live their memories, anytime, any place. With
              Photogranth make your customers feel special.
            </Para>
          </Div>
        </MaxContainer>
        <MaxContainer
          padding="80px 0 0 0"
          bg="#e7fdff"
          paddingSmall="50px 20px"
        >
          <Aligner align="center">
            <GredientHeading>Instant Share 2 ways</GredientHeading>
          </Aligner>
          <Div width="1200px" widthSmall="100%" margin="0 auto">
            <Para align="center">
              <span style={{ color: "#e22f5d" }}>•</span> Share the entire app
              with friends and family with their special memories and reach more
              people instantly.
            </Para>
            <Para align="center">
              <span style={{ color: "#e22f5d" }}>•</span> App allows customers
              to share their photos on Social platforms and your brand with it.
            </Para>
            <Para align="center">
              <span style={{ color: "#e22f5d" }}>•</span> Avail global exposure.
            </Para>
          </Div>
          <Div marginSmall="50px 0 0" margin="0" padding="0">
            {isMobile && (
              <>
                <SlideCarousel>
                  <Div paddingSmall="20px">
                    <div>
                      <Para alignSmall="center">
                        <img
                          src="./images/instashare1.png"
                          alt=""
                          width="80%"
                        />
                      </Para>
                      <Para alignSmall="center">
                        Instant share feature in Photogranth enables your
                        clients to share the entire app with friends and family
                        with their special memories and with this your brand
                        reaches more people instantly.
                      </Para>
                    </div>
                  </Div>
                  <Div paddingSmall="20px">
                    <div>
                      <Para alignSmall="center">
                        <img
                          src="./images/instaShareMoblie1.png"
                          alt=""
                          width="80%"
                        />
                      </Para>
                      <Div widthSmall="100%">
                        <Para alignSmall="center">
                          Also the app allows customers to share their photos on
                          Social platforms and your brand with it avails global
                          exposure.
                        </Para>
                        <Para alignSmall="center" margin="20px 0 0 0">
                          <img
                            src="./images/socialshare.png"
                            alt=""
                            width="50%"
                          />
                        </Para>
                        <Para alignSmall="center" margin="0px 0 0 0">
                          <span style={{ fontWeight: "500" }}>
                            SOCIAL SHARING BUILT-IN
                          </span>
                        </Para>
                      </Div>
                    </div>
                  </Div>
                </SlideCarousel>
              </>
            )}
          </Div>
          {!isMobile && (
            <>
              <Aligner align="center">
                <Div
                  coloums="1fr 1fr"
                  padding="0px"
                  width="900px"
                  maxWidth="1300px"
                >
                  <SwitchButton
                    onClick={() => setShare("App")}
                    active={Share === "App"}
                  >
                    App Sharing with your brand
                  </SwitchButton>
                  <SwitchButton
                    onClick={() => setShare("Social")}
                    active={Share === "Social"}
                  >
                    Social Sharing with your brand
                  </SwitchButton>
                </Div>
              </Aligner>
              {Share === "App" && (
                <DualDiv coloums="60% 40%">
                  <Div>
                    <Para align="center">
                      <img src="./images/instashare1.png" alt="" width="70%" />
                    </Para>
                  </Div>
                  <Div width="500px" widthSmall="100%" alignself="center">
                    <Para align="center">
                      Instant share feature in Photogranth enables your clients
                      to share the entire app with friends and family with their
                      special memories and with this your brand reaches more
                      people instantly.
                    </Para>
                  </Div>
                </DualDiv>
              )}
              {Share === "Social" && (
                <DualDiv coloums="40% 60%">
                  <Div width="500px" widthSmall="100%" alignself="center">
                    <Para align="center">
                      Also the app allows customers to share their photos on
                      Social platforms and your brand with it avails global
                      exposure.
                    </Para>
                    <Para align="center" margin="40px 0 0 0">
                      <img src="./images/socialshare.png" alt="" />
                    </Para>
                    <Para align="center" margin="0px 0 0 0">
                      <span style={{ fontWeight: "500" }}>
                        SOCIAL SHARING BUILT-IN
                      </span>
                    </Para>
                  </Div>
                  <Div>
                    <img src="./images/instashare.png" alt="" width="80%" />
                  </Div>
                </DualDiv>
              )}
            </>
          )}
        </MaxContainer>
        <MaxContainer padding="0px 0 0 0" bg="url(./images/AiBG.png)">
          <DualDiv coloums="40% 60%" paddingSmall="50px 0px">
            <Div width="600px" widthSmall="100%" alignself="center">
              <Aligner align="left">
                <GredientHeading>Artificial Intelligence</GredientHeading>
              </Aligner>
              <Heading
                lineheight="3rem"
                lineheightSmall="2rem"
                margin="0 0 30px 0"
              >
                Allows each one to see their own photo automatically.
              </Heading>
              <Para align="left" margin="0 0 20px 0">
                Every photographer knows ki ‘Har kisiko apni photo dekhni achi
                lagti hai’, Photogranth makes this tough task, a matter of
                seconds.
              </Para>
              <Div margin="0px 0px 0px 20px" padding="0">
                <ul>
                  <li>
                    <Para align="left" margin="0px 0 20px 0">
                      Powerful face recognition and artificial intelligence make
                      folders of each relative, friend automatically who have
                      attended an event where you have clicked a million photos.
                    </Para>
                  </li>
                  <li>
                    <Para align="left" margin="0px 0 20px 0">
                      And each friend or relative can see their photos easily.
                    </Para>
                  </li>
                </ul>
              </Div>
            </Div>
            <Div>
              {!isMobile ? (
                <img src="./images/AImobile.png" alt="" width="100%" />
              ) : (
                <img src="./images/AiMobile1.png" alt="" width="90%" />
              )}
            </Div>
          </DualDiv>
        </MaxContainer>
        <MaxContainer
          padding="100px 0 0 0"
          bg="#efefef"
          paddingSmall="50px 20px"
        >
          <Heading align="center" alignSmall="left">
            Bye! Bye! Old way of Album Selection.{" "}
          </Heading>
          <Aligner align="center">
            <GredientHeading>
              Welcome, a simple, easy album selection process.
            </GredientHeading>
          </Aligner>
          <Div width="1200px" widthSmall="100%" margin="0 auto">
            <Para align="center">
              Say bye! Bye! To the endless waiting period of customers having to
              select their photos post the event for the Album.
            </Para>
          </Div>
          <DualDiv coloums="1fr 1fr 1fr" marginSmall="50px 0">
            <div>
              <Para align="center" alignSmall="center">
                <img src="./images/album1.png" alt="" />
              </Para>
              <Aligner align="center" alignSmall="center">
                <StackedNumber bg="#e22f5d">1</StackedNumber>
              </Aligner>
              <Para
                align="center"
                alignSmall="center"
                margin="30px 50px"
                marginSmall="10px 30px 80px"
              >
                Customers can select photos from anywhere and everywhere
              </Para>
            </div>
            <div>
              <Para align="center" alignSmall="center">
                <img src="./images/album2.png" alt="" />
              </Para>
              <Aligner align="center" alignSmall="center">
                <StackedNumber bg="#89348d">2</StackedNumber>
              </Aligner>
              <Para
                align="center"
                alignSmall="center"
                margin="30px 20px"
                marginSmall="10px 30px 80px"
              >
                Get real time approval on the album and its design
              </Para>
            </div>
            <div>
              <Para align="center" alignSmall="center">
                <img src="./images/album3.png" alt="" />
              </Para>
              <Aligner align="center" alignSmall="center">
                <StackedNumber bg="#413679">3</StackedNumber>
              </Aligner>
              <Para
                align="center"
                alignSmall="center"
                margin="30px 20px"
                marginSmall="10px 30px 0px"
              >
                Generate an e-album instantly using QR codes
              </Para>
            </div>
          </DualDiv>
        </MaxContainer>
        <MaxContainer
          padding="0"
          bg="url(./images/marketingtoolBG.png)"
        >
          <DualDiv coloums="40% 60%" paddingSmall="50px 0px">
            <Div width="600px" widthSmall="100%" alignself="center">
              <Heading
                lineheight="3rem"
                lineheightSmall="2rem"
                margin="0 0 0px 0"
              >
                EFFECTIVE
              </Heading>
              <Aligner align="left">
                <GredientHeading>MARKETING TOOL</GredientHeading>
              </Aligner>
              <Para align="left" margin="0 0 20px 0" marginSmall="20px 0">
                Your own app on the mobile screen of your customer, a way to
                always remain in sight and in mind.
              </Para>
              <Div margin="0px 0px 0px 20px" padding="0">
                <ul>
                  <li>
                    <Para align="left" margin="0px 0 20px 0">
                      Send push notifications of offers, new services during key
                      seasons.
                    </Para>
                  </li>
                  <li>
                    <Para align="left" margin="0px 0 20px 0">
                      Communicate details of already booked events with clients
                      and co-ordinate with absolute ease.
                    </Para>
                  </li>
                  <li>
                    <Para align="left" margin="0px 0 20px 0">
                      Push year round innovative ideas and packages to create
                      new revenue.
                    </Para>
                  </li>
                  <li>
                    <Para align="left" margin="0px 0 20px 0">
                      Get insights - most popular pose, most liked photo and
                      thus pitch with your best work.
                    </Para>
                  </li>
                  <li>
                    <Para align="left" margin="0px 0 20px 0">
                      Increase your brand awareness.
                    </Para>
                  </li>
                </ul>
              </Div>
            </Div>
            <Div padding="0">
              <Para size="1px">.</Para>
              <AbsoluteDiv>
                {isMobile ? (
                  <img
                    src="./images/marketingToolMobile.png"
                    alt=""
                    width="80%"
                  />
                ) : (
                  <img src="./images/marketingtool.png" alt="" width="80%" />
                )}
              </AbsoluteDiv>
            </Div>
          </DualDiv>
        </MaxContainer>
        <MaxContainer bg="url(./images/leadingwayBG.png)">
          <DualDiv>
            <Div margin="80px 0 0 0">
              <img src="./images/leadingway1.png" alt="" />
            </Div>
            <Div marginSmall="50px 0 100px">
              <Aligner align="end">
                <GredientHeading>Leading the way</GredientHeading>
              </Aligner>
              <Heading align="right" alignSmall="left">
                TO CHANGE
              </Heading>
              <Para align="right" margin="30px 0 0">
                Photogranth is revolutionizing the entire industry by bringing
                together the diverse and fragmented stakeholders on one
                platform. Making it easy to connect and do business. New
                monetization opportunities. Business growth, technology
                advantage. All of this on one platform. We enable the Photo
                industry to move from the old and usher into the new way of
                doing business in today’s smart age.
              </Para>
            </Div>
          </DualDiv>
          <Div padding="150px">
            <Para size="1px">.</Para>
          </Div>
        </MaxContainer>
        <MaxContainer padding="50px 0" style={{ justifyContent: "center", display: "flex" }} id="pricing">
          {/* <DualDiv paddingSmall="50px 0"> */}
          <Div alignself="center">
            <Aligner align="center" >
              <GredientHeading>Start Going Viral and Grow Your Business.</GredientHeading>
            </Aligner>
            <Aligner align="center" >
              <GredientHeading>No Payment Required.</GredientHeading>
            </Aligner>
            <Para align="center" margin="20px 0 0 0">
              Pick a Plan Later
            </Para>
            <Para align="center" >
              All our plans are flexible and upgradable to Scale your Business.
            </Para>
            <Div margin="auto" width="350px">
              <PrimaryBtn onClick={() => { window.location.href = "https://ephotogranth.com/auth/register" }}>
                <Para padding="15px 40px">Get Started</Para>
              </PrimaryBtn>
            </Div>
          </Div>
          {/* <Div marginSmall="50px 0 0">
            <Para>
              <img src="./images/ourpartners.png" alt="" />
            </Para>
          </Div> */}
          {/* </DualDiv> */}
        </MaxContainer>
        {/* <MaxContainer padding="100px 50px" paddingSmall="80px 0px">
          <TestimonialCarousel isMobile={isMobile}>
            <Div padding="0px" margin="0px 40px">
              <Para align="left" alignSmall="center">
                <img
                  src="./images/testimonial1.png"
                  alt=""
                  width={isMobile ? "100px" : "150px"}
                />
              </Para>
              <Div bg="#f1f1f1" padding="10px 30px" paddingSmall="20px 30px">
                <Para align="left">
                  Photogranth has enabled me to conduct my business the smart
                  way! The complications of old methods are no longer the part
                  of my way of business!
                  <br />
                  <b>
                    <i>- Lorem Ipsum</i>
                  </b>
                </Para>
              </Div>
            </Div>
            <Div padding="0px" margin="0px 40px">
              <Para align="left" alignSmall="center">
                <img
                  src="./images/testimonial1.png"
                  alt=""
                  width={isMobile ? "100px" : "150px"}
                />
              </Para>
              <Div bg="#f1f1f1" padding="10px 30px" paddingSmall="20px 30px">
                <Para align="left">
                  Photogranth has enabled me to conduct my business the smart
                  way! The complications of old methods are no longer the part
                  of my way of business!
                  <br />
                  <b>
                    <i>- Lorem Ipsum</i>
                  </b>
                </Para>
              </Div>
            </Div>
            <Div padding="0px" margin="0px 40px">
              <Para align="left" alignSmall="center">
                <img
                  src="./images/testimonial1.png"
                  alt=""
                  width={isMobile ? "100px" : "150px"}
                />
              </Para>
              <Div bg="#f1f1f1" padding="10px 30px" paddingSmall="20px 30px">
                <Para align="left">
                  Photogranth has enabled me to conduct my business the smart
                  way! The complications of old methods are no longer the part
                  of my way of business!
                  <br />
                  <b>
                    <i>- Lorem Ipsum</i>
                  </b>
                </Para>
              </Div>
            </Div>
          </TestimonialCarousel>
        </MaxContainer> */}
        {/* <MaxContainer
          bg="url(./images/videoBG.png)"
          padding="100px"
          paddingSmall="50px 20px"
        >
          <Heading
            align="center"
            alignSmall="left"
            margin="50px"
            marginSmall="0px"
          >
            Get a sneak peak into the Photogranth Platform
          </Heading>
          <Aligner align="center">
            <Div marginSmall="50px 0 0" padding="0">
              <video
                src="./video/videoplayback.mp4"
                controls
                width={isMobile ? "100%" : "800px"}
              ></video>
            </Div>
          </Aligner>
        </MaxContainer> */}
        {/* <MaxContainer>
          <DualDiv coloums="50% 50%" paddingSmall="50px 0">
            <Div>
              <Heading margin="0 0 30px 0">
                Get your Own App started on the Photogranth Platform{" "}
              </Heading>
              <div>
                <PrimaryBtn>
                  <Para padding="15px 40px">SIGN UP NOW!</Para>
                </PrimaryBtn>
              </div>
            </Div>
            <Div
              alignself="center"
              bg="url(./images/downloadBG.png)"
              marginSmall="50px 0 0"
              coloumsSmall="1fr 1fr"
              gap="20px"
            >
              <Para align="center">
                <img
                  src="./images/googleplay.png"
                  alt=""
                  width="200px"
                  height="60px"
                />
              </Para>
              <Para align="center">
                <img
                  src="./images/appstore.png"
                  alt=""
                  width="200px"
                  height="60px"
                />
              </Para>
            </Div>
          </DualDiv>
        </MaxContainer> */}
        <MaxContainer id="contact">
          <Div marginSmall="20px">
            <Aligner align="center" alignSmall="center">
              <GredientHeading>Contact Us</GredientHeading>
            </Aligner>
            <Heading align="center">
              We're Always Happy to Assist. Get connected...!
            </Heading>
          </Div>
          <iframe
            title="map"
            allowFullScreen=""
            ariaHidden="false"
            frameBorder="0"
            height="450"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d941.7911421499058!2d72.85861632668743!3d19.23165828699878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b0d0944b7e79%3A0x4ededbfd191a7c59!2sShreeji%20Shopping%20Arcade%2C%20Mahatma%20Gandhi%20Rd%2C%20Sukarwadi%2C%20Borivali%20East%2C%20Mumbai%2C%20Maharashtra%20400066!5e0!3m2!1sen!2sin!4v1603790020181!5m2!1sen!2sin"
            style={{ border: 0 }}
            tabIndex="0"
            width="100%"
          ></iframe>
          <DualDiv coloums="60% 40%" marginSmall="20px 0">
            <DualDiv gap="20px">
              <div>
                <Aligner>
                  <GredientHeading size="30px">Postal Address</GredientHeading>
                </Aligner>
                <Para>
                Corporate Office : Aura Biplex, S V Road, Borivali (West), Mumbai 400 092.
                <br />
                Registered Office : Shanti Nagar, Near N L High School, S V Road, Malad (West), Mumbai 400 064.
                </Para>
              </div>
              <div>
                <Aligner>
                  <GredientHeading size="30px">Office Numbers</GredientHeading>
                </Aligner>
                <Para>
                  Mobile : <a href="tel:+919820540072">+91 98205 40072</a>
                </Para>
              </div>
              <div>
                <Aligner>
                  <GredientHeading size="30px">Our Email</GredientHeading>
                </Aligner>
                <Para>
                  Info :{" "}
                  <a href="mailto:info@photogranth.com">info@photogranth.com</a>{" "}
                </Para>
              </div>
              <div>
                <Aligner>
                  <GredientHeading size="30px">Fast Support</GredientHeading>
                </Aligner>
                <Para>
                  Support :{" "}
                  <a href="mailto:support@photogranth.com">
                    support@photogranth.com
                  </a>{" "}
                </Para>
              </div>
            </DualDiv>
            <Div padding="20px" marginSmall="40px 0">
              <form onSubmit={formik.handleSubmit}>
                <DualDiv margin="10px 0px" padding="0" gap="10px">
                  <div>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    {formik.errors.name ? (
                      <ErrorMsg>{formik.errors.name}</ErrorMsg>
                    ) : null}
                  </div>
                  <div>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.errors.email ? (
                      <ErrorMsg>{formik.errors.email}</ErrorMsg>
                    ) : null}
                  </div>
                </DualDiv>
                <Div margin="10px 0" padding="0">
                  <Input
                    id="phone"
                    name="phone"
                    type="tel"
                    placeholder="Phone"
                    onChange={formik.handleChange}
                    value={formik.values.mobile}
                  />
                  {formik.errors.phone ? (
                    <ErrorMsg>{formik.errors.phone}</ErrorMsg>
                  ) : null}
                </Div>
                <Div margin="10px 0" padding="0">
                  <TextArea
                    id="detail"
                    name="detail"
                    type="text"
                    placeholder="Project Detail"
                    onChange={formik.handleChange}
                    value={formik.values.detail}
                  />
                  {formik.errors.detail ? (
                    <ErrorMsg>{formik.errors.detail}</ErrorMsg>
                  ) : null}
                </Div>

                <PrimaryBtn type="submit">
                  <Para padding="15px 40px">Submit</Para>
                </PrimaryBtn>
              </form>
            </Div>
          </DualDiv>
        </MaxContainer>
      </Main>
      <Footer />
    </div>
  );
}

export default Home;
