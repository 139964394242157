/** @format */

import React, { useState } from 'react';
import styled from 'styled-components';
import { MaxContainer } from './Container.styled';
import { AiOutlineMenu } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import { Link } from 'react-scroll';

// Styled COmponents START

const HeaderWrapper = styled.div`
	background-color: #efefef;
	position: sticky;
	top: 0;
	z-index: 99;
`;

const HeaderContainer = styled.div`
	& img {
		width: 130px;
		height: auto;
	}
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			90deg,
			rgba(239, 79, 74, 1) 0%,
			rgba(94, 45, 135, 1) 100%
		);
	}
	@media screen and (max-width: 500px) {
		padding: 0px;
		& img {
			width: 100px;
			height: auto;
		}
	}
`;

const Menu = styled.div`
	display: flex;
	gap: 40px;
	align-items: center;
	@media screen and (max-width: 500px) {
		display: none;
	}
	ul {
		display: flex;
		gap: 40px;
		li {
			position: relative;
			text-transform: uppercase;
			text-decoration: none;
			list-style: none;
			font-size: var(--para);
			font-weight: 300;
			font-family: 'Poppins', sans-serif;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			& .active::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background: linear-gradient(
					90deg,
					rgba(239, 79, 74, 1) 0%,
					rgba(94, 45, 135, 1) 100%
				);
			}
			& ::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0%;
				height: 2px;
				background: linear-gradient(
					90deg,
					rgba(239, 79, 74, 1) 0%,
					rgba(94, 45, 135, 1) 100%
				);
				transition: all 0.3s ease-in-out;
			}
			& :hover::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background: linear-gradient(
					90deg,
					rgba(239, 79, 74, 1) 0%,
					rgba(94, 45, 135, 1) 100%
				);
				transition: all 0.3s ease-in-out;
			}
		}
	}

	.buttonDiv {
		button {
			background-color: none;
			padding: 7px 25px;
			font-size: 16px;
			font-weight: 300;
			font-family: 'Poppins', sans-serif;
			text-transform: uppercase;
			cursor: pointer;
		}
	}
`;
const MobileMenuContainer = styled.div`
	display: none;
	@media screen and (max-width: 500px) {
		display: grid;
	}
`;
const MobileMenu = styled.div`
	z-index: 9999;
	position: sticky;
	width: 100vw;
	left: 0;
	top: 80px;
	background-color: #efefef;
	padding: 20px;
	ul {
		li {
			list-style: none;
			font-size: 18px;
			font-weight: 300;
			font-family: 'Poppins', sans-serif;
			text-transform: uppercase;
			margin-bottom: 10px;
			&:hover {
				transform: scale(1.03);
				font-weight: 500;
			}
			& .active::after {
				color: red;
			}
		}
	}
	.buttonDiv {
		display: grid;

		button {
			margin-top: 10px;
			background-color: none;
			padding: 7px 25px;
			font-size: 16px;
			font-weight: 300;
			font-family: 'Poppins', sans-serif;
			text-transform: uppercase;
			cursor: pointer;
		}
	}
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			90deg,
			rgba(239, 79, 74, 1) 0%,
			rgba(94, 45, 135, 1) 100%
		);
	}
`;

// Styled COmponents END

function Header() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<>
			<HeaderWrapper>
				<MaxContainer>
					<HeaderContainer>
						<img src='./images/logo.png' alt='' />
						<MobileMenuContainer>
							{!isMenuOpen ? (
								<AiOutlineMenu
									size='30px'
									onClick={() => setIsMenuOpen(true)}
								/>
							) : (
								<GrClose
									size='30px'
									onClick={() => setIsMenuOpen(false)}
								/>
							)}
						</MobileMenuContainer>
						<Menu>
							<ul>
								<li>
									<Link
										activeClass='active'
										to='about'
										spy={true}
										smooth={true}
										offset={-80}
										duration={500}>
										About
									</Link>
								</li>
								<li>
									<Link
										activeClass='active'
										to='photogranth'
										spy={true}
										smooth={true}
										offset={-80}
										duration={500}>
										Photogranth
									</Link>
								</li>
								<li>
									<Link
										activeClass='active'
										to='pricing'
										spy={true}
										smooth={true}
										offset={-80}
										duration={500}>
										Pricing
									</Link>
								</li>
								{/* <li>
									<Link
										activeClass='active'
										to='section1'
										spy={true}
										smooth={true}
										offset={-80}
										duration={500}>
										Blog
									</Link>
								</li>
								<li>
									<Link
										activeClass='active'
										to='section1'
										spy={true}
										smooth={true}
										offset={-80}
										duration={500}>
										News
									</Link>
								</li> */}
								<li>
									<Link
										activeClass='active'
										to='contact'
										spy={true}
										smooth={true}
										offset={-80}
										duration={500}>
										Contact Us
									</Link>
								</li>
							</ul>
							<div className='buttonDiv'>
								{' '}
								<a
									href='https://ephotogranth.com/auth/login'
									target='_blank'
									rel='noreferrer'>
									<button>Login</button>
								</a>
								<a
									href='https://ephotogranth.com/auth/register'
									target='_blank'
									rel='noreferrer'>
									<button>Sign up</button>
								</a>
							</div>
						</Menu>
					</HeaderContainer>
				</MaxContainer>
			</HeaderWrapper>
			{isMenuOpen && (
				<MobileMenu>
					<ul>
						<li>
							<Link
								activeClass='active'
								to='about'
								spy={true}
								smooth={true}
								offset={-80}
								duration={500}
								onClick={() => {
									setTimeout(() => {
										setIsMenuOpen(false);
									}, 600);
								}}>
								About
							</Link>
						</li>
						<li>
							{' '}
							<Link
								activeClass='active'
								to='photogranth'
								spy={true}
								smooth={true}
								offset={-80}
								duration={500}
								onClick={() => {
									setTimeout(() => {
										setIsMenuOpen(false);
									}, 600);
								}}>
								Photogranth
							</Link>
						</li>
						<li onClick={() => setIsMenuOpen(false)}>Pricing</li>
						<li onClick={() => setIsMenuOpen(false)}>Blog</li>
						<li onClick={() => setIsMenuOpen(false)}>News</li>
						<li>
							<Link
								onClick={() => {
									setTimeout(() => {
										setIsMenuOpen(false);
									}, 600);
								}}
								activeClass='active'
								to='contact'
								spy={true}
								smooth={true}
								offset={-80}
								duration={500}>
								Contact Us
							</Link>
						</li>
					</ul>
					<div className='buttonDiv'>
						<div>
							<a
								href='https://photographer.photogranth.com/login'
								target='_blank'
								rel='noreferrer'>
								<button>Login</button>
							</a>
						</div>
						<div>
							<a
								href='https://photographer.photogranth.com/photographer/register'
								target='_blank'
								rel='noreferrer'>
								<button>Sign up</button>
							</a>
						</div>
					</div>
				</MobileMenu>
			)}
		</>
	);
}

// About        Photogranth        Pricing        Blog        News        Contact Us

export default Header;
